import React from "react"
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from "@reach/router"

const duration = 2

const variants = {
  initial: {
    opacity: 0,
    x: "100%",
    transition: { 
      duration: duration, 
    },
  },
  enter: {
    opacity: 1,
    x: "100%",
  },
  exit: {
    x: "-200%",
    transition: { 
      duration: duration, 
      ease: [1, 0.7, 0.3, 0.5],
    },
  },
}



const duration2 = 1.5

const variants2 = {
  initial: {
    opacity: 0,
    x: "100%",
    transition: { 
      duration: duration2, 
    },
  },
  enter: {
    opacity: 1,
    x: "100%",
  },
  exit: {
    x: "-200%",
    transition: { 
      duration: duration2, 
      ease: [0.7, 0, 0.3, 0.7],
      delay: 0.5,
    },
  },
}



const duration3 = 1

const variants3 = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration3,
    },

  },
  exit: {
    opacity: 1,
    transition: { duration: duration3 },
    delay: 5
  },
}

const Transition = (props) => {

    const { children } = props;
    const location = useLocation();
    const cleanLocation = location.pathname.substr(4).replace('/', '');
    const pageVariant = cleanLocation;

    return (
      <div className={`transition-variant--${pageVariant ? pageVariant : 'home'}`}>

          <AnimatePresence>
            <motion.div
              className="page__transition-item-container"
              key={location.pathname}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <div className="page__transition-item page__transition-item--first"></div>    
            </motion.div>
          </AnimatePresence>

          <AnimatePresence>
            <motion.div
              className="page__transition-item-container"
              key={location.pathname}
              variants={variants2}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <div className="page__transition-item page__transition-item--second"></div>
            </motion.div>
          </AnimatePresence>


        <AnimatePresence>
          <motion.main
            key={location.pathname}
            variants={variants3}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {children}
          </motion.main>
        </AnimatePresence>
      </div>
    );

}

export default Transition;